<template>
<div>
    
    <div class="dropdown">
    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="packageGroupListActions" ref="packageGroupListActions" data-bs-toggle="dropdown" aria-expanded="false">
        İşlemler
    </button>
    <ul class="dropdown-menu" aria-labelledby="packageGroupListActions">
        <li v-if="item.packageGroupId==10 || item.packageGroupId==12">
            <a @click.prevent="consultantTypes(item)" class="dropdown-item">Paket Tipleri</a>
            <hr class="dropdown-divider">
        </li>
        <li><a class="dropdown-item" @click.prevent="edit(item)">Düzenle</a></li>
        <li><a class="dropdown-item" @click.prevent="remove(item)">Sil</a></li>
        <li v-if="!item.renewalStatus">
            <hr class="dropdown-divider">
        </li>
        <li>
            <a class="dropdown-item" @click.prevent="specialPrices(item)">Özel Fiyatlar</a>
        </li>
    
        <li v-if="!item.renewalStatus"><a class="dropdown-item" href="#" @click.prevent="change(item)">Abonelik paketine dönüştür</a></li>
        </ul>
    </div>

      
    

</div>
</template>

<script>

export default {
    name:'PackageGroupListActions',
    props:['item','packagekey'],

    methods:{
        consultantTypes(item){
            this.$emit('types',item);
        },
        remove(item){
            this.$emit('remove',item);
        },
        edit(item){
            this.$emit('edit',item);
        },
        change(item){
            this.$emit('change',item);
        },
        specialPrices(item){
            this.$emit('specialPrices',item);
        }
    },
    
   
}
</script>