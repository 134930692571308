<template>
  <div>   
      <div style="display:none">
        <div id="match-form" ref="match-form">
                <div class="row mb-10">
                    <div class="col-lg-3">
                        <label class="col-form-label">Uzman Danışman Tipi</label>                            
                    </div>
                    <div class="col-lg-6">
                        <select name="" class="form-select form-select-solid" id="" v-model="selectedType.consultantTypeId">
                            <option value="">Seçiniz</option>
                            <option :value="item.consultantTypeId" v-for="item in alltypes" :key="item.consultantTypeId">{{item.consultantType}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-10">
                    <div class="col-lg-3">
                        <label class="col-form-label">Görüşme Hakkı</label>                            
                    </div>
                    <div class="col-lg-6">
                        <input type="number" name="" class="form-control form-control-solid" id="" v-model="selectedType.appVcallCount">
                    </div>
                </div>
                <div class="row mb-10">
                    <div class="col-lg-3">
                        <label class="col-form-label">Üst Fiyat Limiti</label>                            
                    </div>
                    <div class="col-lg-6">
                        <input type="number" name="" class="form-control form-control-solid" step=".01" id="" v-model="selectedType.priceLimit">
                    </div>
                </div>
        </div>   
        </div>   
      <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Paket Adı</th>
                    <th>Uzman Danışman Türü</th>
                    <th>Görüşme Hakkı</th>
                    <th>Üst Fiyat Limiti</th>
                    <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in types" :key="i">
                    <td>{{item.packageName}}</td>
                    <td>{{item.doctorType}}</td>
                    <td>{{item.appVcallCount}}</td>
                    <td>{{item.priceLimit}}</td>
                    <td>
                        <div class="dropdown">
                            <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="packageGroupListActions" ref="packageGroupListActions" data-bs-toggle="dropdown" aria-expanded="false">
                                İşlemler
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="packageGroupListActions">
                                <li><a class="dropdown-item" @click.prevent="editMatch(item)">Düzenle</a></li>
                                <li><a class="dropdown-item" @click.prevent="removeMatch(item)">Sil</a></li>
                            </ul>
                        </div>


                    </td>
                    </tr>            
                </tbody>
              </table>
               <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button type="reset" @click.prevent="cancelConsType()" class="btn btn-white btn-active-light-primary me-2">Paketlere geri dön</button>
                <button @click.prevent="newMatch()" class="btn btn-primary">Yeni Ekle</button>
            </div>
            <!--end::Actions-->
  </div>
  
</template>

<script>
import api from '@/core/services/ApiService'
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
    name:'PackageConsultantTypeMatch',
    props:['selectedPackage','alltypes'],
    data(){
        return {
            types:null,
            mode:'new',
            selectedType:{
                consultantTypeId:"",
                appVcallCount:null,
                priceLimit:null,
                packageId:this.selectedPackage.packageId,
            }
        }
    },

    methods:{
        getMatches(){
             api.get('/Package/ListPackageDoctorType/'+this.selectedPackage.packageId).then((res)=>{
                this.types = res.data;
            }).catch((err)=>{
                console.log(err);
            })
        },
        cancelConsType(){
            this.$emit('cancelConsType');
        },
        editMatch(item){
            this.selectedType.consultantTypeId = item.consultantTypeId;
            this.selectedType.appVcallCount = item.appVcallCount;
            this.selectedType.packageConsultantTypeId = item.packageConsultantTypeId;
            this.selectedType.packageId = item.packageId;
            this.selectedType.priceLimit = item.priceLimit;
            console.log(this.selectedType);
            this.mode='edit';
            this.newMatch();
        },
        saveMatch(){
            let url = this.mode=='new' ? '/Package/NewPackageDoctorType' : '/Package/UpdatePackageDoctorType';
            console.log(this.selectedType);
            api.post(url,this.selectedType).then((res)=>{
                this.getMatches();
            });
        },
        removeMatch(item){
            Swal.fire({
                title:'Uzman Danışman Tipi Kaldırma',
                text:'Eşleşmeyi kaldırmak istediğinize emin misiniz?',
                icon:'warning',
            }).then((res)=>{
                
                if(res.isConfirmed){
                    api.get('/Package/DeletePackageDoctorType/'+item.packageConsultantTypeId).then((res)=>{
                        Swal.fire({
                            text:'Eşleşme kaldırıldı'
                        }).then(()=>{
                            this.getMatches();
                        })
                    })
                }
            })
        }, 
        newMatch(){
            let html = this.$refs['match-form'];
            Swal.fire({
                title:'Pakete Uzman Tipi Ekleme',
                html:html,
                width:900
            }).then((res)=>{
                if(res.isConfirmed){
                    this.saveMatch();
                }
            });
        }
    },

    mounted(){
        this.getMatches();
    }
}
</script>

<style>
.swal2-container .swal2-html-container{max-height: 500px !important;}

</style>