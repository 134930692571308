<template>
    <div>
        <div class="row mb-10">
            <div class="col-lg-4 fv-row">
                <label class="col-form-label">Paket Adı</label>
                <input type="text" name="packageName" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.packageName"  />
            </div>

            <div class="col-lg-3 fv-row">
                <label class="col-form-label">Satınalınabilme Sayısı</label>
                <input type="number" name="buyLimit" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.buyLimit"/>
            </div>

            <div class="col-lg-3 fv-row">
                <label class="col-form-label">Ücret</label>
                <input type="number" name="price" step=".01" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.price" />
            </div>

            <div class="col-lg-2 fv-row">
                <label class="col-form-label">Sıra</label>
                <input type="number" name="orderNo" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.orderNo" />
            </div>
        </div>

        <div class="row mb-10">
            <div class="col-lg-3 fv-row">
                <label class="col-form-label">Renkler</label>
                <div class="d-flex">
                    <div class="d-flex align-items-center me-3">
                        <span class="me-2">
                            Renk 1 
                        </span>
                        <input type="color" name="color1" v-model="selectedPackage.color1" id="color1">
                    </div>

                    <div class="d-flex align-items-center">
                        <span class="me-2">
                            Renk 2 
                        </span>
                        <input type="color" name="color2" v-model="selectedPackage.color2" id="color2">
                    </div>
                </div>
                
            </div>

            <div class="col-lg-3 fv-row">
                <label class="col-form-label">İndirim Tutarı</label>
                <input type="number" name="discount" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"  v-model="selectedPackage.discount" />
            </div>

            <div class="col-lg-3 fv-row">
                <label class="col-form-label">Kazanılan Puan</label>
                <input type="number" name="earnScore" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.earnScore" />
            </div>            

            <div class="col-lg-3 fv-row">
                <label class="col-form-label">Süre/Aylık</label>
                <input type="number" name="duration" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.duration"/>
            </div>

            
        </div>

        <div class="row mb-10">
            <div class="col-lg-4 fv-row">
                <label class="col-form-label">Açıklama</label>
                <textarea name="description" rows="5" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.description"></textarea>
                
            </div>

            <div class="col-lg-4 fv-row">
                <label class="col-form-label">2. Açıklama</label>
                <textarea name="description2" rows="5" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.description2"></textarea>
            </div>

            <div class="col-lg-4 fv-row">
                <label class="col-form-label">Fiyat Açıklaması</label>
                <textarea name="priceText" rows="5" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.priceText"></textarea>
            </div>
        </div>

        <div class="row mb-10">
            <div class="col-lg-3 fv-row">
                <label class="col-form-label">Puan Paketi</label>
                
                <div class="d-flex align-items-center mt-3">
                    <label class="form-check form-check-inline form-check-solid me-5">
                        <input class="form-check-input" value="AC" name="scoreStatus" :checked="selectedPackage.scoreStatus == 'AC'" type="radio" v-model="selectedPackage.scoreStatus" />
                        <span class="fw-bold ps-2 fs-6"> Evet </span>
                    </label>
                    <label class="form-check form-check-inline form-check-solid me-5">
                        <input class="form-check-input" value="DA" name="scoreStatus" :checked="selectedPackage.scoreStatus == 'DA'" type="radio" v-model="selectedPackage.scoreStatus" />
                        <span class="fw-bold ps-2 fs-6"> Hayır </span>
                    </label>
                </div>
                
            </div>
            <div class="col-lg-3 fv-row" v-show="selectedPackage.scoreStatus=='AC'">
                <label class="col-form-label">Satın alma puanı</label>
                <input type="number" name="buyScore" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.buyScore" />
            </div>
        </div>
        <div class="row mb-10">

            <div class="col-lg-3 fv-row">
                <label class="col-form-label">Havuz Doktor Paketi</label>
                <div class="d-flex align-items-center mt-3">
                    <label class="form-check form-check-inline form-check-solid me-5">
                        <input class="form-check-input" value="AC" name="poolStatus" :checked="selectedPackage.poolStatus == 'AC'" type="radio" v-model="selectedPackage.poolStatus" />
                        <span class="fw-bold ps-2 fs-6"> Evet </span>
                    </label>
                    <label class="form-check form-check-inline form-check-solid me-5">
                        <input class="form-check-input" value="DA" name="poolStatus" :checked="selectedPackage.poolStatus == 'DA'" type="radio" v-model="selectedPackage.poolStatus" />
                        <span class="fw-bold ps-2 fs-6"> Hayır </span>
                    </label>
                </div>
            </div>

            <div class="col-lg-3 fv-row" v-show="selectedPackage.poolStatus=='AC'">
                <label class="col-form-label">Hemen Görüşme Hakkı</label>
                <input type="number" name="poolVcallCount" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"  v-model="selectedPackage.poolVcallCount" />
            </div>

            <div class="col-lg-3 fv-row" v-show="selectedPackage.poolStatus=='AC'">
                <label class="col-form-label">Aylık Hak Tanımı</label>
                <div class="d-flex align-items-center mt-3">
                    <label class="form-check form-check-inline form-check-solid me-5">
                        <input class="form-check-input" value="1" name="poolVcallMonthly" :checked="selectedPackage.poolVcallMonthly" type="radio" v-model="selectedPackage.poolVcallMonthly" />
                        <span class="fw-bold ps-2 fs-6"> Evet </span>
                    </label>
                    <label class="form-check form-check-inline form-check-solid me-5">
                        <input class="form-check-input" value="0" name="poolVcallMonthly" :checked="selectedPackage.poolVcallMonthly===false" type="radio" v-model="selectedPackage.poolVcallMonthly" />
                        <span class="fw-bold ps-2 fs-6"> Hayır </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row">

           

            <div class="col-lg-4 fv-row">
                <label class="col-form-label">Randevulu Görüşme Hakkı / Toplam Paket Süresi Boyunca</label>
                
                <div class="d-flex align-items-center mt-3">
                    <label class="form-check form-check-inline form-check-solid me-5">
                        <input class="form-check-input" value="AC" name="appointmentStatus" :checked="selectedPackage.appointmentStatus == 'AC'" type="radio" v-model="selectedPackage.appointmentStatus" />
                        <span class="fw-bold ps-2 fs-6"> Evet </span>
                    </label>
                    <label class="form-check form-check-inline form-check-solid me-5">
                        <input class="form-check-input" value="DA" name="appointmentStatus" :checked="selectedPackage.appointmentStatus == 'DA'" type="radio" v-model="selectedPackage.appointmentStatus" />
                        <span class="fw-bold ps-2 fs-6"> Hayır </span>
                    </label>
                </div>
            </div>

             <div class="col-lg-2 fv-row">
                <label class="col-form-label">Durum</label>
                <div class="d-flex align-items-center mt-3">
                    <label class="form-check form-check-inline form-check-solid me-5">
                        <input class="form-check-input" value="AC" name="packageStatus" :checked="selectedPackage.packageStatus == 'AC'" type="radio" v-model="selectedPackage.packageStatus" />
                        <span class="fw-bold ps-2 fs-6"> Aktif </span>
                    </label>
                    <label class="form-check form-check-inline form-check-solid me-5">
                        <input class="form-check-input" value="DA" name="packageStatus" :checked="selectedPackage.packageStatus == 'DA'" type="radio" v-model="selectedPackage.packageStatus" />
                        <span class="fw-bold ps-2 fs-6"> Pasif </span>
                    </label>
                </div>
            </div>

           
        </div>

      
    </div>
</template>

<script>

export default {
    name:'EditPackage',
    props:['selectedPackage','mode','packageGroupId'],
   
        
}
</script>