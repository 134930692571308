<template>
  <div class="card mb-5 mb-xl-12">
    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
      
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Paketler</h3>

        
      </div>      
    </div>
    
    
    
    <div id="kt_account_profile_details" class="collapse show" v-if="packages">

        <div id="package-detail" v-if="showDetail">
            <div class="card-body border-top p-9">
                
                <PackagDetailForm :selectedPackage="selectedPackage" :mode="mode" v-if="selectedPackage" :packageGroupId="packageGroupId" />
            </div>

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button type="reset" @click.prevent="cancelForm()" class="btn btn-white btn-active-light-primary me-2">Vazgeç</button>

                <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" @click.prevent="updatePackage()" class="btn btn-primary">
                    <span class="indicator-label"> Kaydet </span>
                    <span class="indicator-progress">
                    Lütfen bekleyin...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
            <!--end::Actions-->
        </div>

        <div id="package-constypes" v-if="consType">
          <div class="card-body border-top p-9">
            
            <ConsultantTypes :selectedPackage="this.selectedPackage" :alltypes="activeConsTypes" @cancelConsType="cancelConsType" />
          </div>

         
        </div>

        <div id="special-prices" v-if="showSpecialPrices">
          <div class="card-body border-top p-9">
            
            <PackageSpecialPrices :selectedPackage="this.selectedPackage" :alltypes="activeConsTypes" @cancelSpecialPrices="cancelSpecialPrices" />
          </div>

         
        </div>

        


        <div class="card-body border-top p-9" v-show="!showDetail && !consType && !showSpecialPrices">
            <div class="table-responsive">
                <a href="" @click.prevent="addNewPackage()" class="btn btn-primary float-right">Yeni Paket Ekle</a>
              <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Sıra</th>
                    <th>Paket Adı</th>
                    <th>Ücret</th>
                    <th>Kazanılan Puan</th>
                    <th>Süre/Aylık</th>
                    <th>Satın Alınabilme Sayısı</th>
                    <th>Açıklama</th>
                    <th>Havuz Doktor Paketi</th>
                    <th>Puan Paketi Paketi</th>
                    <th>Randevu Görüşme Paketi</th>
                    <th>Abonelik Paketi</th>                    
                    <th>Durum</th>
                    <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in packages" :key="i">
                    <td>{{item.orderNo}}</td>
                    <td>{{item.packageName}}</td>
                    <td>{{item.price}}</td>
                    <td>{{item.earnScore}}</td>
                    <td>{{item.duration}}</td>
                    <td>{{item.buyLimit}}</td>
                    <td>{{item.description}}</td>
                    <td><span :class="'badge badge-light-'+statusses.class[item.poolStatus]">{{statusses.label[item.poolStatus]}}</span></td>  
                    <td><span :class="'badge badge-light-'+statusses.class[item.scoreStatus]">{{statusses.label[item.scoreStatus]}}</span></td>  
                    <td><span :class="'badge badge-light-'+statusses.class[item.appointmentStatus]">{{statusses.label[item.appointmentStatus]}}</span></td>  
                    <td><span :class="'badge badge-light-'+renewal.class[item.renewalStatus ? 1 : 0]">{{renewal.label[item.renewalStatus ? 1 : 0]}}</span></td>  
                    <td><span :class="'badge badge-light-'+statusses.class[item.packageStatus]">{{statusses.label[item.packageStatus]}}</span></td>  
                    
                    <td><ActionsMenu :item="item" @specialPrices="specialPrices($event)" :packagekey="i" @types="consultantTypes($event)" @edit="editPackage($event)" @change="changeRenewalStatus(item)"  @remove="removePackage($event)" /></td>
                    </tr>            
                </tbody>
              </table>
      
            </div>
        </div>
    </div>


    
        
    
  </div>


</template>



<script>
import api from '@/core/services/ApiService'
import ActionsMenu from "@/components/dropdown/PackageListActions.vue";
import PackageActionToolbar from "@/components/actions/PackageToolbar.vue";
import ConsultantTypes from '@/components/modals/forms/PackageConsultantTypeMatch.vue'; 
import PackageSpecialPrices from '@/components/modals/forms/PackageSpecialPrices.vue'; 
import PackagDetailForm from '@/components/modals/forms/EditPackage.vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";


import Swal from "sweetalert2/dist/sweetalert2.js";

const newPackage = {
  sPackageGroup:null,
  packageName:null,
  priceText:"",
  description:null,
  description2:"",
  duration:null,
  poolVCallCount:0,
  poolVCallMonthly:null,
  price:null,
  discount:null,
  packageStatus:null,
  scoreStatus:null,
  poolStatus:null,
  appointmentStatus:null,
  renewalStatus:false,
  buyLimit:null,
  earnScore:0,
  buyScore:0,
  orderNo:null,
  packageGroupId: null
};

export default  {
  name:'PackageGroups',
  components:{
      ActionsMenu,
      PackagDetailForm,
      ConsultantTypes,
      PackageSpecialPrices
      
  },
  data(){
    return{        
      consType:false,
      showSpecialPrices:false,
      consForm:false,
      activeConsTypes:null,
        selectedPackage:newPackage,
        mode:'new',

      showDetail:false,
      
      statusses : {
        class:{
          'AC':'success',
          'DA':'danger'
        },
        label:{
          'AC':'Aktif',
          'DA':'Pasif'

        }
      },
      renewal : {
        class:{
          1:'success',
          0:'danger'
        },
        label:{
          1:'Evet',
          0:'Hayır'

        }
      },
      packages:null,
      

    }
  },

  

  methods:{
   
    cancelConsType(){
      
      this.consType=false;
    },
    cancelSpecialPrices(){
      
      this.showSpecialPrices=false;
    },

    specialPrices(item){
      
      this.selectedPackage = item;
      this.showSpecialPrices = true;
    },

      changeRenewalStatus(item){
        Swal.fire({
          title:'Emin misiniz?',
          text:item.packageNam+ ' paketini, abonelik paketine dönüştürmek üzeresiniz. Bu işlemin geri dönüşü yoktur!',
          icon:'warning',
          showCancelButton:true
        }).then((result)=>{
          if(result.isConfirmed){
            this.selectedPackage = item;
            this.mode = 'edit';
            this.selectedPackage.renewalStatus=true;
            this.updatePackage();
          }
        });
      },
      editPackage(item){
          this.showDetail=true;
          this.mode='edit';
          this.selectedPackage = item;
      },
      removePackage(item){
        Swal.fire({
          icon:'warning',
          title:'Paketi silmek üzeresiniz',
          text:item.packageName +' paketini silmek istediğinize emin misiniz?\nBu işlemin geri dönüşü yoktur!',
          showCancelButton:true
        }).then((result)=>{
            if(result.isConfirmed){
              api.get('/Package/DeletePackage/'+item.packageId).then((res)=>{
                Swal.fire({
                  title:'Paket Silme',
                  icon:'success',
                  text:'Paket silindi',
                }).then(()=>{
                  window.location.reload();
                })
              });
            }
          });
      },

      cancelForm(){
          this.showDetail=false;
      },

      addNewPackage(){
        this.mode='new';
        this.selectedPackage = newPackage;        
        this.showDetail = true;
      },

      consultantTypes(item){
        this.selectedPackage = item;
        this.consType=true;
        
      },

      updatePackage(){
          let apiAddress = this.mode=='new' ? '/Package/NewPackage' : '/Package/UpdatePackage';
          this.selectedPackage.poolVcallMonthly = this.selectedPackage.poolVcallMonthly == "1" ? true : false;
          api.post(apiAddress,this.selectedPackage).then((res)=>{
              Swal.fire({
                  text:'Paket bilgileri güncellendi',
                  icon:'success',
              }).then(()=>{
                  window.location.reload();
              });
          }).catch((err)=>{
              console.log(err);
          })
      }
  },


  created(){
      let aComponent = {component:PackageActionToolbar, props:{},events:['addPackage']};
     setCurrentPageBreadcrumbs("Paket Listesi", ["Paketker"],aComponent);
     
     let packageGroupId = this.$route.params.package_id;
     this.selectedPackage.packageGroupId = parseInt(packageGroupId);
     api.get('/Package/ListPackage/JJ/JJ/'+packageGroupId).then((res)=>{
         this.packages = res.data;
     });

      api.get('/User/ListDoctorType/JJ').then((res)=>{
          this.activeConsTypes = res.data;
      });
   
  },

}

</script>
<style scoped>
input:disabled{cursor: not-allowed; background-color: #eee;}
</style>